<template>
    <b-link  class="appstatusindicator" v-if="serviceStatus" target="_blank" href="https://status.wbs.ac.uk" rel="Warwick Business School service status page">
        <div class="status-indicator" :style="indicatorBgColor" v-bind:title="serviceStatus"></div>
    </b-link>
</template>

<script>

export default {
  name: "SystemStatusIndicator",
  data() {
    return {
      indicatorBgColor: "background-color:#A0A0A0",
      serviceStatus: ""
    };
  },
  mounted(){
    this.getSystemStatus();
  },
  methods: {
    async getSystemStatus() {
      const response = await fetch("https://status.wbs.ac.uk/api/v2/status.json");
      const appStatus = await response.json();
      this.serviceStatus = appStatus.status.description;
      let errorCon = 0;
      
      switch (appStatus.status.indicator) {
        case "none" :
          this.indicatorBgColor = "background-color:transparent";
          this.serviceStatus = null;
          break;
        case "critical" :
          this.indicatorBgColor = "background-color:#fe2712";
          errorCon = 1;
          break;
        case "major" :
          this.indicatorBgColor = "background-color:#fe5a1d";
          errorCon = 2;
          break;
        case "minor" :
          this.indicatorBgColor = "background-color:#ffba00";
          errorCon = 3;
          break;
        default:
          this.indicatorBgColor = "background-color:#A0A0A0";
          break;
      }
      const serviceStatusCookie = "mini-seen-service-status-notification";
      if(!this.$cookies.isKey(serviceStatusCookie) || this.$cookies.get(serviceStatusCookie) != appStatus.status.indicator) {
        this.$cookies.set(serviceStatusCookie, appStatus.status.indicator, "1h");
        this.showToastr(errorCon);
      }
    },
    showToastr(con) {
      switch(con){
        case 1:
        case 2:
          this.$bvToast.toast(this.serviceStatus, {title : "System status", href:"https://status.wbs.ac.uk", variant : "danger"});
          break;
        case 3:
          this.$bvToast.toast(this.serviceStatus, {title : "System status", href:"https://status.wbs.ac.uk", variant : "warning"});
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped>
/* status indicator */

.appstatusindicator {
  text-align: center;
  align-items: center;
}
.appstatusindicator div.status-indicator {
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  margin-top: 0.165rem;
  background-color: #A0A0A0;
}
</style>