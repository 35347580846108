import ProcessFeed from "./processFeed";
import FeedService from "../services/feed.service";

export default async function fetchFeed(timeShiftInSeconds) {
    const feed = await FeedService.getFeed(timeShiftInSeconds);
    return new ProcessFeed(feed);
}



