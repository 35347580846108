import http from "./http.service";

class FeedService {

    endpoint = '/api/feed';

    getFeed(timeShiftInSeconds) {

        return http.get(this.endpoint, {
			headers: {},
            params: {
                timeShiftInSeconds: timeShiftInSeconds
            }})
            .then(response =>
                Promise.resolve(response.data))
            .catch(error => {
                return Promise.reject(error);
            });

    }

}

export default new FeedService();