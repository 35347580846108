import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import store from "@/store";

import ResponseError from "./responseError";
import RequestConfig from "./requestConfig";

const options = {statusCodes: [401]};
const authRefreshApi = '/api/auth/refresh';

const refreshAuthLogic = failedRequest => {

    // don't try and refresh auth for failed auth calls
    if (failedRequest.config.url.startsWith("/api/auth/")) return Promise.reject(failedRequest);

    return axios.post(authRefreshApi).then(tokenRefreshResponse => {
        const { accessToken } = tokenRefreshResponse.data;
        store.commit('auth/refreshedToken',accessToken );
        setAuthorizationHeader(accessToken);
        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + accessToken ;
        failedRequest.response.config.apiRetry=true;
        return Promise.resolve();
    })
    .catch(e => Promise.reject(e))
}

createAuthRefreshInterceptor(axios, refreshAuthLogic, options);

axios.interceptors.response.use(response=>{
    return Promise.resolve(response);
}, interceptorResponseError );

axios.interceptors.request.use(
    interceptorRequestConfig,
    error => {
        return Promise.reject(error);
    });

function setAuthorizationHeader(jwt) {
    if (jwt) axios.defaults.headers.common["Authorization"] = 'Bearer ' + jwt;
}

function clearAuthorizationHeader() {
    delete axios.defaults.headers.common["Authorization"];
}

async function interceptorResponseError  (error)  {
    const responseError = new ResponseError(error);
   return  await responseError.main();
}

function interceptorRequestConfig(config) {
    const requestConfig = new RequestConfig(config)
    return requestConfig.main();
}

export default {
    get: axios.get,
    post: axios.post,
    setAuthorizationHeader,
    clearAuthorizationHeader
};