<template>
    <span v-if="entry.downloadableFiles.length">
        <b-dropdown
                variant="light"
                :id="entry.id + '-attachments'">
            <template v-slot:button-content>
                <font-awesome-icon :icon="['fas', 'paperclip']"/>
            </template>
            <b-dropdown-item
                    v-for="file in entry.downloadableFiles"
                    :key="entry.id + file.version + file.number"
                    @click="downloadFile(file.name, file.index, $bvToast)">
                <font-awesome-icon class="text-primary" :icon="['fas', 'cloud-download-alt']"/>
                {{ file.name }}
            </b-dropdown-item>
        </b-dropdown>
        <b-tooltip
                :target="entry.id + '-attachments'"
                triggers="hover"
                placement="right">
            <span v-if="entry.downloadableFiles.length > 1">
                There are {{ entry.downloadableFiles.length }} attachments available.
            </span>
            <span v-else>
                    There is an attachment available.
                </span>
        </b-tooltip>
    </span>
</template>

<script>

    import { downloadFile } from "@/services/attachment_download.service";

    export default {
        name: 'attachments-dropdown',
        props: {
            entry: {
                type: Object,
                require: true
            }
        },
        mounted() {
            if (this.entry.downloadableFiles.length) {
                let dropdownButton = this.$el.getElementsByTagName("button")[0];
                if (this.entry.downloadableFiles.length > 1) {
                    dropdownButton.setAttribute("aria-label", 
                        this.entry.downloadableFiles.length + " Attachments available. View attachments");
                } else {
                    dropdownButton.setAttribute("aria-label", "Attachment available. View attachment");
                }
            }

        },
        methods: {
            downloadFile
        }
    }

</script>

<style scoped>
    .entry-details img {
        max-width: 100%
    }
</style>