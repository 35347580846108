import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: process.env.VUE_APP_AZURE_ACTIVEDIRECTORY_CLIENTID,
        authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_ACTIVEDIRECTORY_TENANTID}`,
        redirectUri: "/login",
        postLogoutRedirectUri: "/login?freshLogin=true",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        allowRedirectInIframe: true,
    },
}

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"],
};
