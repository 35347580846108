const initialState = {
  myWbsConnectionStatus: null
};

export const appState = {
  namespaced: true,
  state: initialState,
  getters: {
    isMyWbsUnderHeavyLoad: state => {
      return state.myWbsConnectionStatus === 'redirect';
    },
    isMyWbsDown: state => {
      return state.myWbsConnectionStatus === 'down';
    }
  },
  actions: {
    setMyWbsConnectionStatus({ commit }, connectionStatus) {
      commit('setMyWbsConnectionStatus', connectionStatus);
    }
  },
  mutations: {
	setMyWbsConnectionStatus(state, connectionStatus) {
		state.myWbsConnectionStatus = connectionStatus;
    }
  }
};
