
import store from "@/store";
import router from '@/router';

export default class ResponseError {

    constructor(error) {
        this.error = error;
        this.loginPath = '/login';
        this.path = router.currentRoute.path;
        this.isLoginPage = [this.loginPath, "/"].includes(this.path);
        this.status = this.error.response ? this.error.response.status : 503;
        this.unauthorizedForbidden = (this.status === 401 || this.status === 403);
        this.expectedError = (this.status >= 400 && this.status < 500);
        this.apiRetry = !!(this.error.config.apiRetry);
        this.isTokenRefresh = (this.error.config.url === '/api/auth/refresh');
        this.failedTokenRefresh = (this.isTokenRefresh && this.status !== 200 );
    }

    async main() {

        if (this.isLoginPage && this.unauthorizedForbidden) return Promise.reject(this.error);

        if (this.failedTokenRefresh || this.apiRetry) {

            await this.redirectToLogin();

        }

        if (!this.expectedError) {
            //TODO log the exception error
            console.log("An unexpected error occurred");
        }

        return Promise.reject(this.error);

    }


    async redirectToLogin() {

        if (this.isLoginPage) return;

        console.log('Redirect to Login page');

        await store.dispatch("auth/logout");

        return router.push(this.loginPath);

    }

}