export default class RequestConfig {

    constructor(config) {
        this.config = config;
    }


    declareDebugProps() {
        this.requestUrl = this.config.url;
        this.token = this.config.headers.common.Authorization;
        this.isTokenRefresh = (this.requestUrl === '/api/auth/refresh');
        this.hasToken = !!(this.token);
        this.apiRetry = !!(this.config.apiRetry);
    }


    debugRequestConfigState() {
        const requestConfigState = {
            config: this.config,
            requestUrl: this.requestUrl,
            hasToken: this.hasToken,
            isTokenRefresh: this.isTokenRefresh,
            apiRetry: this.apiRetry
        };
       console.log({requestConfigState});
    }

    main() {
        if (process.env.VUE_APP_HTTP_SERVICE_DEBUG === 'true') {
            this.declareDebugProps();
            this.debugRequestConfigState();
        }
        return this.config;
    }


}