<template>
  <b-modal id="accessibilitystatement" size="lg" ok-only title="Accessibility Statement" title-tag="h1" tabindex="0" aria-role="button">
    <section aria-label="Accessibility Statement">
      <h2>Accessibility Statement for mini-my</h2>
      <p>This statement applies to the University of Warwick’s Warwick Business School Learning Management System (LMS) -
        <a target="_blank" href="https://mini-my.wbs.ac.uk/">mini-my</a>
      </p>
      <p>This activity scheduling system is run by the University of Warwick. It is a partner site to the WBS Learning Management System, my.wbs. <a target="_blank" href="https://mini-my.wbs.ac.uk/">mini-my</a> is a read-only web site publishing notices about scheduled activities from the LMS. mini-my contains events and notices from my.wbs that are timestamped and current, such as lecture timetable information and assessment deadlines. </p>
      <p>We want as many people as possible to be able to use this website. For example, you should be able to</p>
      <ul>
        <li>Zoom in up to 300% without problems.</li>
        <li>Navigate the site Using a Keyboard.</li>
        <li>Navigate the site using speech recognition software.</li>
        <li>Listen to most of the LMS using a screen reader.</li>
      </ul>
      <p><a target="_blank" href="https://mcmw.abilitynet.org.uk/">AbilityNet</a> has advice on making your device easier to use if you have a disability.</p>

      <h2>How accessible is this Learning Management System?</h2>
      <p>This Web Site is fully compatible with the Web Content Accessibility Guidelines version 2.1 AA standard.</p>

      <h2>Feedback and Contact Information</h2>
      <p>If you need information on mini-my in a different format please ask your teaching team or contact helpdesk, via email to <b>help@wbs.ac.uk</b> or call to <b>024 765 22522</b></p>
      <p>If you are a student with existing arrangements or reasonable adjustments, please contact <a target="_blank" href="https://warwick.ac.uk/services/wss/students/disability">Well-being and Student Support</a>. They offer brief consultations Monday-Friday from 10am-3pm, either on Teams or face to face. Students have the opportunity to join a virtual queue and speak to a well-being professional for a same-day response (the average wait time is under 20 minutes).</p>

      <h2>Reporting accessibility problems with mini-my</h2>
      <p>We’re always looking to improve the accessibility of this scheduling system. mini-my is developed by eSolutions within Warwick Business School. If you find any problems that aren’t listed on this page or think we’re not meeting accessibility requirements, contact our help desk, via email to <b>help@wbs.ac.uk</b> or call to <b>024 765 22522</b></p>
      <p>When you contact us by email there is a process in place that will acknowledge your contact and give you a reference code to enable you to follow our response.</p>

      <h2>Enforcement procedure</h2>
      <p>The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018 (the ‘accessibility regulations’). If you’re not happy with how we respond to your complaint, contact the <a target="_blank" href="https://www.equalityadvisoryservice.com/">Equality Advisory and Support Service (EASS)</a>.</p>

      <h2>Technical Information about this website’s accessibility</h2>
      <p>The University of Warwick is committed to making its websites accessible, in accordance with the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.</p>
      <p>This accessibility statement applies to the University of Warwick Warwick Business School’s Learning Management System, mini-my.</p>

      <h5>Compliance status</h5>
      <p>This Web Site is fully compatible with the Web Content Accessibility Guidelines version 2.1 AA standard.</p>

      <h2>Preparation of this accessibility statement</h2>
      <p>This statement was prepared on 08/09/2022.</p>
      <p>This was a self-assessment performed by Warwick Business School’s development team, eSolutions. The LMS is a large role-based application. For the roles of student, faculty and administrator we articulated the user-stories describing how mini-my is used from each perspective. A team of developers then used Lighthouse and Siteimprove to complete each of the actions described in the user stories to the appropriate standard or record where that could not be achieved.</p>

    </section>
  </b-modal>
</template>

<script>
export default {
  name: "AccessibilityStatement"
}
</script>

<style scoped>

</style>