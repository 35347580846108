<template>
    <div class="container wrapper">
        <div role="heading" aria-level="1" class="row align-items-center my-auto h-100">
            <b-card class="card card-container img-top border-0 p-0 mx-auto" no-body>
                <b-card-img fluid src="../assets/wbs_primelogo.svg"  alt="Warwick Business School Logo"/>

                <b-card-body class="px-0 overflow-auto">
                    <hr/>

                    <b-form @submit.prevent>
                        <div>
                            <b-form-group
                                    id="fieldset-1"
                                    invalid-feedback="Enter a valid 6-digit two-factor code"
                                    :state="validateCode"
                            >
                                <b-form-input
                                        class="text-center"
                                        placeholder="Two Factor Code..."
                                        id="code"
                                        v-model="code"
                                        v-validate="'required'"
                                        :state="validateCode"
                                        v-on:keyup.stop.enter="handleTwoFactor()"
                                        type="text"
                                        name="code"
                                        trim
                                />

                            </b-form-group>

                            <b-form-group id="fieldset-2">

                              <b-form-select
                                  v-model="durationDays"
                                  :options="durationOptions"
                                  name="duration"
                              />

                            </b-form-group>
                        </div>

                        <div>
                            <b-form-group>
                                <b-button
                                        @click="handleTwoFactor()"
                                        block
                                        variant="primary"
                                        :disabled="loading"
                                        class="login"
                                >
                                    <progress-spinner v-show="loading" class="mr-2" size="sm"/>
                                    <font-awesome-icon :icon="['fas', 'caret-right']" class="mt-1"
                                                       style="float:right;"/>
                                    <span>Submit Code</span>
                                </b-button>
                            </b-form-group>
                        </div>

                        <div v-if="message">
                            <b-form-group>
                                <div class="alert alert-danger mb-0" role="alert">{{message}}</div>
                            </b-form-group>
                        </div>
                    </b-form>
                    <div class="mt-4">
                         <b-link target="_blank" :href="twoFactorHelpUrl">Trouble with 2FA?</b-link>
                    </div>
                    <div class="mt-2">
                        <b-link target="_blank" :href="termsOfUseUrl">Terms of use</b-link>
                    </div>
                    <div class="mt-2">
                      <b-link v-b-modal.accessibilitystatement tool-tip="Accessibility Statement">Accessibility Statement</b-link>
                    </div>
                </b-card-body>
            </b-card>
        </div>
      <!-- Accessibility statement modal -->
      <AccessibilityStatement/>
    </div>
</template>

<script>
    import AccessibilityStatement from "../components/AccessibilityStatement";

    export default {
        name: "TwoFactorForm",
        components: {AccessibilityStatement},
        data() {
            return {
                code: '',
                durationDays: 0,
                durationOptions: [
                    {value: 0, text: "Re-authenticate at next login"},
                    {value: 7, text: "Re-authenticate after one week"},
                    {value: 30, text: "Re-authenticate after one month"},
                    {value: 180, text: "Re-authenticate after six months"},
                    {value: 365, text: "Re-authenticate after one year"}
                ],
                loading: false,
                termsOfUseUrl: `http://www2.warwick.ac.uk/services/its/about/policies/`,
                twoFactorHelpUrl: "https://warwick.ac.uk/fac/soc/wbs/central/issu/help/kb/internet/my_wbs/2fa",
                message: ""
            };
        },
        computed: {
            validateCode() {
                if (!this.code) return null;
                return this.code.length == 6;
            }
        },
        methods: {
            onSubmit(ev) {
                ev.preventDefault();
            },
            handleTwoFactor() {
                this.loading = true;
                this.$validator.validateAll().then(isValid => {
                    if (!isValid) {
                        this.loading = false;
                        return;
                    }

                    if (this.code) {
                        this.$store.dispatch("auth/twoFactor", { code: this.code, durationDays: this.durationDays }).then(
                            () => {
                                this.$router.replace(this.$route.query.to || "/");
                            },
                            error => {
                                this.loading = false;
                                this.message =
                                    error.response?.status === 401
                                        ? "Incorrect two factor code"
                                        : "Error performing two factor authentication"
                            }
                        );
                    }
                });
            }
        }
    };
</script>

<style scoped>
    @media screen and (min-height: 600px) {
        .container.wrapper {
            height: 100vh;
        }
    }

    .card-container.card {
        width: 275px !important;
    }

    .login {
        background-color: #0655a3;
        border-color: rgba(0, 0, 0, 0.1);
    }
</style>
