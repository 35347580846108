<template>

    <entry-wrapper
        :entry="entry"
        type="assessment"
        :icon="['fas', 'pencil-alt']">

        <b-card-text>
            <small ref="submitStatusMessage" v-if="entry.submitted">You have already submitted this assessment.</small>
            <div ref="submitStatusMessage" v-else>
                You have <strong>until {{dueDate.format('HH:mm on Do MMMM')}}</strong>
                to complete and submit this assessment.
            </div>
            <div v-if="!needsMyWbsLink" class="alert alert-info mt-2 mb-0">
                You are not expected to upload a document/file for this assessment through my.wbs
            </div>
        </b-card-text>

        <b-button
                ref="submission_Button"
                :disabled="attemptSubmission"
                v-if="!entry.submitted && needsMyWbsLink"
                @click="doSubmission"
                variant="success">
            {{entry.documentExpected ? 'Submit' : 'View'}} Assessment
        </b-button>

    </entry-wrapper>

</template>

<script>

    import MyWbsService from '../../../services/mywbs.service';
    import EntryWrapper from "../fragments/EntryWrapper";

    export default {
        name: "AssignmentEntry",
        components: { EntryWrapper },
        props: {
            entry: {
                type: Object,
                required: true
            },
            now: {
                type: Object,
                required: true
            }
        },
        data: function() {
            return {
                attemptSubmission: false,
                dueDate: this.entry.end,
                needsMyWbsLink: this.entry.documentExpected || this.entry.actionExpected
            }
        },
        methods: {
            doSubmission() {
                this.attemptSubmission = true;
                MyWbsService.authenticateForAssignmentSubmission(this.entry)
                    .then(url => {
                        window.location.replace(`${url}`);
                    })
                    .catch(() => {
                        this.$bvToast.toast("There was an error redirecting to my.wbs", {
                            title: "Failed to go to my.wbs"
                        });
                    })
                    .finally(() => {
                        this.attemptSubmission = false;
                    });
            }
        }
    }
</script>
