import http from "./http.service";

const API_URL = '/api/';

class AuthService {
  login(user) {
    return http
      .post(API_URL + 'auth/signin', {
        username: user.username,
        password: user.password
      })
      .then(response => {
          // Keep in sync with server-side logic
          if (process.env.VUE_APP_AZURE_ACTIVEDIRECTORY_FEATURE_ENABLED === 'true') {
              if (response.data && response.data.status === 'success' && response.data.loginHint) {
                  return Promise.resolve(response.data);
              }
          }
          return Promise.resolve(this.extractToken(response))
      })
      .catch(error => Promise.reject(error))
  }

  twoFactor(userId, code, durationDays) {
    return http
        .post(API_URL + 'auth/twofactor', {
            code: code,
            durationDays: durationDays,
            userId: userId
        })
        .then(response => Promise.resolve(this.extractToken(response)))
        .catch(error => Promise.reject(error))
  }

  oidcSignin(idToken) {
      return http
          .post(API_URL + 'auth/oidc-signin', {
              idToken: idToken
          })
          .then(response => Promise.resolve(this.extractToken(response)))
          .catch(error => Promise.reject(error))
  }

  //we will end up here if:
  //1. The JWT in memory is due to expire
  //2. We have no JWT in memory and we want to see if our refresh token is still valid (we will get a 401 if it isn't)
  refreshToken(user) {
      const data = user ? {username: user} : {};
      return http
        .post(API_URL + 'auth/refresh', data)
        .then(response => Promise.resolve(this.extractToken(response)))
        .catch(e => Promise.reject(e))
  }

  invalidateRefreshToken() {
      return http
          .post(API_URL + "auth/invalidate", {}, { headers: {} })
          .then(t => Promise.resolve(t))
          .catch(e => Promise.reject(e))
  }

    impersonate(impersonationId) {
        return http
            .post(API_URL + 'impersonation/impersonate', {impersonationId}, { headers: {} })
            .then(response => Promise.resolve(this.extractToken(response)))
            .catch(e => Promise.reject(e))
    }

    resetImpersonation() {
        return http
            .post(API_URL + 'impersonation/reset', {}, { headers: {} })
            .then(response => Promise.resolve(this.extractToken(response)))
            .catch(e => Promise.reject(e))
    }


  extractToken(response) {
      return response.data.accessToken;
  }

  validateUsername(username) {
      return http
          .post(API_URL + 'auth/validate-username', username)
          .then(response => Promise.resolve(response))
          .catch(error => Promise.reject(error))
  }

}

export default new AuthService();
