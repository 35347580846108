<template>

    <entry-wrapper
        :entry="entry"
            :icon="icon"
            :type="type">

        <b-card-text>
            <div ref="now" v-if="startingSoon">
                Starts <strong>in {{timeFromStart}}</strong>
            </div>
            <div ref="now" v-else-if="inProgress">
                Started <strong>{{timeFromStart}} ago</strong>
            </div>
            <div ref="now" v-else-if="ended">
                Event has ended
            </div>
            <div class="mt-2" v-if="hasLocation">
                <font-awesome-icon :icon="['fas', 'map-marker-alt']"/>
                {{ entry.location }}
            </div>
        </b-card-text>

        <view-details :entry="entry"/>

        <span :id="entry.id + '-join'">
            <b-button
                    variant="primary"
                    :disabled="!readyToJoin || attemptJoin"
                    v-if="isWbsLive"
                    v-on:click="doLaunch"
                    class="mr-2">
                Join Event
            </b-button>
            <b-button
                    ref="buttonHasLink"
                    variant="primary"
                    :disabled="!readyToJoin"
                    v-else-if="hasLink"
                    @click="handleOnClickEventNavigate"
                    class="mr-2">
                Join Event
            </b-button>
        </span>
        <b-tooltip
                ref="joinToolTip"
                v-if="!readyToJoin && now.isBefore(end)"
                :target="entry.id + '-join'"
                triggers="hover"
                placement="right">
            You can join this event nearer the time
        </b-tooltip>
        <b-tooltip
                ref="joinToolTip"
                v-if="end.isBefore(now)"
                :target="entry.id + '-join'"
                triggers="hover"
                placement="right">
            This event has ended
        </b-tooltip>

        <attachments-dropdown :entry="entry"/>

    </entry-wrapper>

</template>
<script>

    import humanizeDuration from "humanize-duration";

    import fetchClassroomUrl from '../../../services/classroom_launch.service';
    import EntryWrapper from "../fragments/EntryWrapper";
    import AttachmentsDropdown from "../fragments/AttachmentsDropdown";
    import ViewDetails from "../fragments/ViewDetails";

    export default {
        name: "DurationEntry",
        components: {ViewDetails, AttachmentsDropdown, EntryWrapper},
        props: {
            entry: {
                type: Object,
                required: true
            },
            now: {
                type: Object,
                required: true
            },
            startCountdownCutoffDuration: {
                type: Array,
                required: true
            },
            joinCutoffDuration: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                attemptJoin: false,
                start: this.entry.start,
                end: this.entry.end
            }
        },
        computed: {
            icon: function() {
                return {
                    'WBS_LIVE' : ['fas', 'desktop'],
                    'LECTURE' : ['fas', 'chalkboard-teacher'],
                    'SEMINAR' : ['fas', 'users'],
                    'WORKSHOP' : ['fas', 'users'],
                    'LAB' : ['fas', 'computer']
                }[this.entry.type] || ['fas', 'calendar-day']
            },
            type: function() {
                return {
                    'WBS_LIVE' : "wbs-live",
                    'LECTURE' : "lecture",
                    'SEMINAR' : "seminar",
                    "WORKSHOP": "workshop",
                    "LAB": "lab"
                } [this.entry.type] || 'event'
            },
            isWbsLive: function() {
                return this.entry.type === 'WBS_LIVE'
            },
            hasLocation: function() {
                return !this.isWbsLive && this.entry.location && !this.hasLink
            },
            hasLink: function () {
                return !this.isWbsLive && !!this.entry.location.match(/^https?:\/\//)
            },
            readyToJoin: function() {
                return (
                    this.now.isAfter(this.start.clone().subtract(...this.joinCutoffDuration))
                    && this.end.isAfter(this.now)
                )
            },
            startingSoon: function() {
                return (
                    this.start.isAfter(this.now)
                    && this.start.isBefore(this.now.clone().add(...this.startCountdownCutoffDuration))
                )
            },
            inProgress: function() {
                return !this.start.isAfter(this.now) && this.end.isAfter(this.now)
            },
            ended: function() {
                return this.end.isBefore(this.now)
            },
            timeFromStart: function() {
                const diff = this.start.diff(this.now);
                return Math.abs(diff) >= 60000
                    ? humanizeDuration(diff, { units: ['h', 'm'], round: true})
                    : "less than a minute";
            }
        },
        methods: {
            doLaunch() {

                if (this.isWbsLive) {

                    this.attemptJoin = true;

                    const classroomId = this.entry.classroomId;

                    fetchClassroomUrl(classroomId)
                        .then(url => {
                            window.location = url
                        })
                        .catch(() => {
                            this.$bvToast.toast("There was an error joining the event", {
                                title: "Error joining event"
                            });
                        })
                        .finally(() => this.attemptJoin = false);

                }
            },
            handleOnClickEventNavigate() {
                window.location.assign(this.entry.location);
            }
        }
    }
</script>
