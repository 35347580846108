<template>
    <details @toggle="toggle" class="mce-accordion">
        <summary>
            <font-awesome-icon :icon="icon" :style="{ color: '#1662AC' }"/> 
            <span v-html="summary"></span>
        </summary>
        <div class="mce-accordion-body" v-html="body"></div>
    </details>
</template>

<script>
    export default {
        name: "MceAccordion",
        props: {
            summary: {
                type: String,
                required: true
            },
            body: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                isOpen: false
            }
        },
        computed: {
            icon: function() {
               return this.isOpen ? ['fas', 'chevron-down'] : ['fas', 'chevron-right'];
            }
        },
        methods: {
            toggle() {
                this.isOpen = !this.isOpen;
            }
        }
    }
</script>

<style scoped>
    .mce-accordion {
        background-color: #fff;
        border: 1px solid rgba(0,0,0,.125);
    }
    .mce-accordion > .mce-accordion-body {
        padding: 0px 12px;
        margin-bottom: 1rem;
    }
    .mce-accordion > summary {
        display: list-item;
        padding: 10px 12px;
        cursor: pointer;
        font-weight: 600;
    }
    .mce-accordion > summary::marker {
        content: none;
    }
    .mce-accordion > summary::-webkit-details-marker {
        display: none;
    }
    .mce-accordion > summary svg {
        margin-right: 8px;
        width: 1em;
    }
</style>