<template>
    <font-awesome-icon :icon="['fas', 'spinner']" :size="size" spin/>
</template>

<script>
    export default {
        name: "ProgressSpinner",
        props: {
            size: {
                type: String,
                required: false
            }
        }
    }
</script>
