import http from "./http.service";

const myWbsHost = `https://${process.env.VUE_APP_MYWBS_HOST}`;
const myWbsAuthenticateUrl = `${myWbsHost}/${process.env.VUE_APP_MYWBS_AUTH_ROUTE}`;

class MyWbsService {

    authenticateMyWbs() {
        return http.post(
            `${myWbsAuthenticateUrl}`,
            {},
            {headers: {},  withCredentials: true}
            );
    }

    authenticateForAssignmentSubmission(assignment) {

        const {assessmentId, moduleOccasionLibraryId, assignmentId} = assignment;

        const redirectTo = `${myWbsHost}/-/academic/${moduleOccasionLibraryId}/assessment/?assref=${assessmentId}#a${assessmentId}`;

        return http.post(
			`/api/mywbsaccess/forassignment/${assignmentId}`,
            {},
            {headers: {}, withCredentials: true}
            )
            .then(response => this.authenticateMyWbs(response.data.accessToken))
            .then(() => {
                return Promise.resolve(redirectTo);
            })
            .catch(error => {
                return Promise.reject(error);
            });

    }

    async redirect() {

        await this.authenticateMyWbs();

        window.location.replace(`https://${process.env.VUE_APP_MYWBS_HOST}/`);

    }

}

export default new MyWbsService();
