<template>
  <div class="container wrapper">
    <div role="heading" aria-level="1" class="row align-items-center my-auto vh-100">
      <b-card class="card card-container img-top border-0 p-0 mx-auto" no-body>
        <b-card-img fluid src="../assets/wbs_primelogo.svg"  alt="Warwick Business School Logo"/>

        <b-card-body class="px-0 overflow-auto">
          <h5 class="text-center"><strong>Signing out of session</strong></h5>
          <div class="text-center my-3" v-if="loading">
            <progress-spinner size="2x"/>
          </div>
          <div class="text-center my-3" v-else>
            <p>{{ message }}</p>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import ProgressSpinner from '../components/util/ProgressSpinner.vue'
import { useMsal } from "@/composition-api/useMsal";

export default {
  name: "OIDCLogoutPage",
  components: {
    progressSpinner: ProgressSpinner
  },
  computed: {
    isProduction() {
      return process.env.VUE_APP_AZURE_ACTIVEDIRECTORY_IS_PRODUCTION === 'true'
    },
    myWbsAppUrl() {
      return this.isProduction ? 'my.wbs.ac.uk' : 'beta-my.wbs.ac.uk'
    }
  },
  data() {
    return {
      loading: true,
      message: ""
    }
  },
  created() {
    this.performLogout();
  },
  methods: {
    performLogout() {
      const {instance} = useMsal();
      try {
        instance.logoutRedirect({
          account: instance.getActiveAccount(),
          onRedirectNavigate: () => {
            return false;
          }
        })
            .then(() => {
              this.logoutOtherApps().then(() => {
                this.$store.dispatch('auth/logout');
                this.message = "You have been signed out.";
              });
            })
            .catch(() => {
              this.message = "Failed to sign out. Please reload the page.";
            })
            .finally(() => {
              this.loading = false;
            });
      } catch (error) {
        console.error(error);
        this.message = "Failed to sign out. Please reload the page.";
      }
    },
    logoutOtherApps() {
      const otherApps = [`https://${this.myWbsAppUrl}/?event=logout-oidc`];
      const logoutPromises = otherApps.map(app => this.logoutAppInIframe(app));
      return Promise.all(logoutPromises);
    },
    logoutAppInIframe(appUrl) {
      return new Promise((resolve) => {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = `${appUrl}`;

        const timeoutId = setTimeout(() => {
          document.body.removeChild(iframe);
          console.warn(`Logout timeout for ${appUrl}`);
          resolve();
        }, 5000);

        iframe.onload = () => {
          clearTimeout(timeoutId);
          document.body.removeChild(iframe);
          resolve();
        };

        iframe.onerror = () => {
          clearTimeout(timeoutId);
          document.body.removeChild(iframe);
          console.warn(`Logout failed for ${appUrl}`);
          resolve();
        };

        document.body.appendChild(iframe);
      });
    }
  }
}
</script>