<template>
    <b-list-group v-if="entry.downloadableFiles.length">
        <b-list-group-item
            v-for="file in entry.downloadableFiles" :key="entry.id + file.version + file.number"
            @click="downloadFile(file.name, file.index, $bvToast)" href="#">
            <font-awesome-icon class="text-primary" :icon="['fas', 'cloud-download-alt']"/> {{file.name}}
        </b-list-group-item>
    </b-list-group>
</template>

<script>

    import { downloadFile } from "@/services/attachment_download.service";

    export default {
        name: "FileList",
        props: {
            entry: {
                type: Object,
                required: true
            }
        },
        methods: {
            downloadFile
        }
    }

</script>
