<template>

    <entry-wrapper
        :entry="entry"
        :icon="['fas', 'user-friends']"
        type="progress-meeting"
        :typeName="name"
        :title="title">

        <b-card-text>
            <div ref="now" v-if="startingSoon">
                Starts <strong>in {{timeFromStart}}</strong>
            </div>
            <div ref="now" v-else-if="inProgress || ended">
                Started <strong>{{timeFromStart}} ago</strong>
            </div>
        </b-card-text>

        <template v-slot:footer>
            <b-col sm="12" md="6" class="pl-0 pr-sm-0">
                <font-awesome-icon :icon="['far', 'calendar-alt']"/>
                {{start.format('HH:mm on Do MMMM')}}
            </b-col>
        </template>

    </entry-wrapper>

</template>
<script>

import humanizeDuration from "humanize-duration";
import EntryWrapper from "../fragments/EntryWrapper";

export default {
    name: "ProgressMeeting",
    components: {EntryWrapper},
    props: {
        entry: {
            type: Object,
            required: true
        },
        now: {
            type: Object,
            required: true
        },
        startCountdownCutoffDuration: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            start: this.entry.start,
            end: this.entry.end
        }
    },
    computed: {
        startingSoon: function() {
            return (
                this.start.isAfter(this.now)
                && this.start.isBefore(this.now.clone().add(...this.startCountdownCutoffDuration))
            )
        },
        inProgress: function() {
            return !this.start.isAfter(this.now) && this.end.isAfter(this.now)
        },
        ended: function() {
            return this.end.isBefore(this.now)
        },
        timeFromStart: function() {
            const diff = this.start.diff(this.now);
            return Math.abs(diff) >= 60000
                ? humanizeDuration(diff, { units: ['h', 'm'], round: true})
                : "less than a minute";
        },
        user: function() {
            return this.$store.getters['auth/user'];
        },
        meetingType: function() {
            return this.user.programme === 'PHD' ? 'Supervisor' : 'Tutor';
        },
        name: function() {
            return this.user.isStaff ? "Meeting" : `${this.meetingType} Meeting`
        },
        title: function() {
            return `Meeting with ${this.entry[this.user.isStaff ? 'studentName' : 'staffName']} (${this.entry.status})`
        }
    }
}
</script>
