import http from "./http.service";

export default function(classroomId) {

    return http.post(
        '/api/launch/classroom/' + classroomId,
        {},
        {
            headers: {}
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error);
        });

}