<template>

    <entry-wrapper
        :entry="entry"
        :icon="icon"
        :type="type">

        <b-card-text>
            <!-- ??? -->
        </b-card-text>

        <view-details :entry="entry"/>

        <attachments-dropdown :entry="entry"/>

        <template v-slot:footer>
            <b-col v-if="relevantNow" sm="12" class="pl-0 pr-sm-0">
                <font-awesome-icon :icon="['far', 'calendar-check']"/>
                <span v-if="!timesUp"> {{timeRemaining}}</span>
                <span v-else> less than a minute</span>
                left <small>(expires {{end.format('HH:mm on Do MMM')}})</small>
            </b-col>
            <b-col v-else sm="12" class="pl-0 pr-sm-0">
                <start-to-end-time :start="start" :end="end"/>
            </b-col>
        </template>

    </entry-wrapper>

</template>
<script>

    import EntryWrapper from "../fragments/EntryWrapper";
    import AttachmentsDropdown from "../fragments/AttachmentsDropdown";
    import ViewDetails from "../fragments/ViewDetails";
    import humanizeDuration from "humanize-duration";
    import StartToEndTime from "@/components/feed/fragments/StartToEndTime";

    export default {
        name: "ScheduledEntry",
        components: {StartToEndTime, ViewDetails, AttachmentsDropdown, EntryWrapper},
        props: {
            entry: {
                type: Object,
                required: true
            },
            now: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                start: this.entry.start,
                end: this.entry.end,
                duration: humanizeDuration(
                    this.entry.start.diff(this.entry.end),
                    { largest : 1, round: true })
            }
        },
        computed: {
            icon() {
                return {
                    "SCHEDULED_RESOURCE": ['fas', 'file-alt'],
                    "SCHEDULED_ANNOUNCEMENT": ['fas', 'bullhorn']
                }[this.entry.type];
            },
            type() {
                return {
                    "SCHEDULED_RESOURCE": "resource",
                    "SCHEDULED_ANNOUNCEMENT": "announcement"
                }[this.entry.type];
            },
            timeRemaining() {
                return humanizeDuration(
                    this.now.diff(this.entry.end),
                    { largest : 1, round: true })
            },
            relevantNow() {
                return this.now.isAfter(this.start);
            },
            timesUp() {
                return this.end.diff(this.now, 'minute') < 1;
            }
        }
    }
</script>
