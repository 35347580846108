<template>
    <b-modal id="help" title="Help" size="lg" ok-only title-tag="h1">
        <section aria-label="Help">
            <h2>
                WBS eSolutions Help Desk
            </h2>
            <p>For technical support relating to WBS systems, contact the eSolutions Help Desk:</p>
            <ul class="list-unstyled pl-4">
                <li class="pb-2">
                    <font-awesome-icon :icon="['fas', 'link']" fixed-width class="mr-2"/>
                    <a href="https://helpdesk.wbs.ac.uk/">online portal</a>
                </li>
                <li class="pb-2">
                    <font-awesome-icon :icon="['far', 'envelope']" fixed-width class="mr-2"/>
                    <a href="mailto:help@wbs.ac.uk">help@wbs.ac.uk</a>
                </li>
            </ul>
            <div v-if="currentUser.status === 'Student'">
                <h2>
                    Programme Team
                </h2>
                <p>For other matters, contact your programme team<span v-if="!!programmeEmail">:</span><span v-else>.</span></p>
                <ul class="list-unstyled pl-4" v-if="!!programmeEmail">
                    <li class="pb-2">
                        <font-awesome-icon :icon="['far', 'envelope']" fixed-width class="mr-2"/>
                        <a :href="`mailto:${programmeEmail}`">{{programmeEmail}}</a>
                    </li>
                </ul>
            </div>
        </section>
    </b-modal>
</template>

<script>
    export default {
        name: "HelpPage",
        computed: {
            currentUser() {
                return this.$store.getters['auth/user'];
			},
            programmeEmail() {
                return this.currentUser.programmeEmail
            }
        }
    }
</script>

<style scoped>

</style>