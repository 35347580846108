<template>
    <span>
        <font-awesome-icon :icon="['far', 'calendar-alt']"/>
        <span v-if="start.isSame(end)">
            {{end.format('HH:mm Do MMM')}}
        </span>
        <span v-else-if="start.isSame(end, 'day')">
            {{start.format('HH:mm')}} to {{end.format('HH:mm')}} on {{end.format('Do MMMM')}}
        </span>
        <span v-else>
            {{start.format('HH:mm Do MMM')}} to {{end.format('HH:mm Do MMM')}}
        </span>
    </span>
</template>

<script>
    export default {
        name: 'start-to-end-time',
        props: {
            start: {
                type: Object,
                require: true
            },
            end: {
                type: Object,
                require: true
            }
        }
    }
</script>