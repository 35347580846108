<template>
    <b-modal
            id="impersonate-user"
            title="Impersonate Another User"
            ok-only
            ok-variant="danger"
            ok-title="Impersonate"
            @ok="onImpersonate"
            @close="initializeImpersonateForm"
            title-tag="h1">

        <section aria-label="Impersonate">
            <b-form @submit.prevent="">

                <div>
                    <b-form-group
                            id="fieldset"
                            label=""
                            label-for="impersonate"
                            :invalid-feedback=invalidMessage
                            :state="validatePersonId">
                        <b-form-input
                                id="impersonate"
                                v-model="impersonationId"
                                v-validate="'required'"
                                :state="validatePersonId"
                                v-on:keyup.enter="onImpersonate"
                                placeholder="Enter Person ID or Username"
                                type="text"
                                name="impersonate"
                                trim
                        ></b-form-input>
                    </b-form-group>
                </div>

                <div>
                    <b-form-group>
                        <div v-if="displayErrorMessage" class="alert alert-danger" role="alert">
                            {{displayErrorMessage}}
                        </div>
                    </b-form-group>
                </div>

            </b-form>
        </section>

    </b-modal>
</template>

<script>
    export default {
        name: "ImpersonationForm",
        data: function () {
            return {
                impersonationId: null,
                validMinLengthImpersonationId: 4,
                invalidMessage: `Enter at least 4 characters`,
                errorMessage:
                    `You must enter a valid person id or user code,
                    and the user must be on a course or programme you are permitted to impersonate.`,
                displayErrorMessage: '',
                loadingImpersonation: false
            };
        },
        computed: {
            currentUser() {
                return this.$store.getters['auth/user'];
            },
            validatePersonId() {
                if (!(this.impersonationId)) return null;
                return (this.impersonationId.length >= this.validMinLengthImpersonationId);
            },
        },
        methods: {
            onSubmit(ev) {
                ev.preventDefault();
            },
            onImpersonate(bvModalEvt) {

                bvModalEvt.preventDefault();

                this.loadingImpersonation = true;
                this.displayErrorMessage = '';

                const doImpersonation = async () => {
                    try {
                        await this.$store.dispatch("auth/impersonate", this.impersonationId);
                        this.$router.go(0);
                        this.$bvModal.hide('impersonate')
                    } catch (e) {
                        this.displayErrorMessage = this.errorMessage;
                    } finally {
                        this.loadingImpersonation = false;
                    }
                };

                if (this.impersonationId) {
                    doImpersonation();
                } else {
                    this.loadingImpersonation = false;
                }

            },
            initializeImpersonateForm(){
                this.impersonationId='';
                this.displayErrorMessage = '';
            }
        }
    };
</script>
