<template>
    <div class="loading-overlay">
        <progress-spinner size="2x"/>
    </div>
</template>

<script>

    import ProgressSpinner from '../components/util/ProgressSpinner.vue'
    import MyWbsService from "../services/mywbs.service";

    export default {
        name: "goToMyWbs.vue",
        components: {
            progressSpinner: ProgressSpinner
        },
        async beforeCreate () {

            try {

                await MyWbsService.redirect();

            } catch (error) {

                this.$root.$bvToast.toast(
                    'It was not possible to log in to my.wbs at this time. Please try again later.',
                    {
                        title: 'Failed to go to my.wbs',
                        variant: 'warning',
                    });

                await this.$router.push("/");

            }

        }
    }
</script>

<style scoped>
    .loading-overlay {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100px;
        z-index: 10;
    }
</style>
