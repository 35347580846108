import FeedEntry from "../models/FeedEntry";
import * as R from 'ramda';

export default class ProcessFeed {

    constructor(feed) {
        this.feed = feed;
        this.declare();
        this.setStudentSavedCareersPlusEvents();
        this.processFeed();
        this.augmentEntryAddedToStudentLibrary();
        return this.processedFeed;
    }

    declare() {
        this.careersPlusRoute = '/-/careers/37235/item/id/';
        this.careersPlusModuleTitle = 'WBS CareersPlus';
        this.typeName = 'Event';
    }


    setStudentSavedCareersPlusEvents() {
        const careersPlusEventsAddedToStudentsLibrary = this.getCareersPlusEventsAddedToStudentsLibrary();
        const careersPlusEvents = this.feed.filter(e => e.moduleTitle === this.careersPlusModuleTitle);
        this.studentSavedCareersPlusEvents = R.innerJoin(this.isMatchingCareersPlusEvent, careersPlusEvents, careersPlusEventsAddedToStudentsLibrary);
    }

    processFeed() {
        this.processedFeed = this.feed
            .filter(e => !e.location || !this.isCareersPlusEventAddedToStudentsLibrary(e.location))
            .map(e => new FeedEntry(e))
            .sort((e1, e2) => e1.start.diff(e2.start));
    }

    augmentEntryAddedToStudentLibrary() {
        this.processedFeed = this.processedFeed.reduce(
            (records, current) => {
                if (current.typeName === this.typeName) {
                    current.addedToStudentLibrary = (R.innerJoin(this.isMatchingEventById, [current], this.studentSavedCareersPlusEvents).length === 1);
                }
                records.push(current);
                return records
            }, []
        )
    }

    getCareersPlusEventsAddedToStudentsLibrary() {
        return this.feed.filter(e => this.isCareersPlusEventAddedToStudentsLibrary(e.location));
    }

    isMatchingCareersPlusEvent(careersPlusEvent, studentLibraryEvent) {
        return (careersPlusEvent.title === studentLibraryEvent.title && careersPlusEvent.start === studentLibraryEvent.start);
    }

    isMatchingEventById(event1, event2) {
        return (event1.id === event2.id);
    }

    isCareersPlusEventAddedToStudentsLibrary(route) {
        return route?.includes(this.careersPlusRoute);
    }

}