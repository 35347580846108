<template>

    <entry-wrapper
        :entry="entry"
        :icon="['fas', 'clock']"
        type="rolling-deadline">

        <!-- assessment has been submitted -->
        <b-card-text v-if="entry.submitted">
            <small>You have submitted this assessment.</small>
        </b-card-text>

        <!-- deadline has passed, and assessment wasn't downloaded -->
        <b-card-text v-else-if="deadlinePassed && !entry.downloaded">
            <div v-if="!entry.aepAssessment" class="text-danger">The deadline has passed for this assessment.</div>
            <div :class="!entry.aepAssessment && 'alert alert-danger alert mt-2 mb-0'">
                The deadline for this assessment <strong>was {{end.format('HH:mm on Do MMMM')}}</strong>
            </div>
        </b-card-text>

        <!-- assessment hasn't been downloaded -->
        <b-card-text v-else-if="!entry.downloaded">
            <div v-if="start.isSame(end)">
                You have <strong>until {{end.format('HH:mm on Do MMMM')}}</strong>
                to complete and submit this assessment.
                <span v-if="entry.aepRolling">
                    <br><small>(your deadline may be earlier, depending on when you start the assessment)</small>
                </span>
            </div>
            <div v-else-if="downloadSoon">
                <span v-if="entry.aepAssessment">Your assessment is scheduled to start </span>
                <span v-else>Download will be available </span>
                <strong>in {{timeFromStart}}</strong>.
            </div>
            <div v-else-if="downloadNow && (!entry.duration || entry.aepFixed)">
                You can {{entry.aepAssessment ? 'start' : 'download'}} this assessment now.
            </div>
            <div v-else-if="downloadNow">
                {{entry.aepAssessment ? 'Start' : 'Download'}}
                <strong>by {{recommendedDownloadDeadline.format('HH:mm on Do MMMM')}}</strong>
                to allow the full time to complete and submit this assessment.
            </div>
            <div v-else-if="downloadLate">
                The deadline for this assessment <strong>is {{end.format('HH:mm on Do MMMM')}} </strong>
                <span v-if="entry.aepRolling">
                    <br><small>(your deadline may be earlier, depending on when you start the assessment)</small>
                </span>
            </div>
            <div v-else-if="now.isBefore(start)">
                <span v-if="entry.aepAssessment">Your assessment is scheduled to start </span>
                <span v-else>You will be able to download this assessment </span>
                <strong>{{entry.aepAssessment ? 'at' : 'from'}} {{start.format('HH:mm on Do MMMM')}}</strong>.
            </div>

            <div v-if="!start.isSame(end) && entry.duration">
                <div v-if="downloadSoon || downloadNow " class="alert alert-info mt-2 mb-0">
                    After {{entry.aepAssessment ? 'starting' : 'downloading'}}, you will have
                    <span v-if="!entry.aepFixed">
                        <strong>{{duration}}</strong> to complete and submit
                        the assessment, <strong>or until {{end.format('HH:mm on Do MMMM')}}</strong>,
                        whichever comes first.
                    </span>
                    <span v-else>
                        <strong>until {{end.format('HH:mm on Do MMMM')}}</strong> to complete and submit
                        the assessment
                    </span>
                </div>
                <div v-else-if="downloadLate" class="alert alert-warning mt-2 mb-0">
                    <span v-if="entry.aepAssessment">If you've not yet started, you </span>
                    <span v-else>You </span>
                    no longer have the full time permitted to complete this assessment.
                    After {{entry.aepAssessment ? 'starting' : 'downloading'}},
                    you will have <strong>until {{end.format('HH:mm on Do MMMM')}}</strong>
                    to complete and submit the assessment.
                </div>
            </div>
        </b-card-text>

        <!-- assessment has been downloaded -->
        <b-card-text v-else>
            <div v-if="!deadlinePassed">
                <div>
                    You have <strong>until {{actualend.format('HH:mm:ss on Do MMMM')}}</strong>
                    to complete and submit this assessment.
                </div>
                <div class="alert alert-info mt-2 mb-0">
                    You downloaded this assessment at {{downloadedAt.format('HH:mm:ss on Do MMMM')}}.
                    <span v-if="downloadedAt.isAfter(recommendedDownloadDeadline)">There was insufficient time
                    before the deadline to enable the maximum permitted time for this assessment.</span>
                    <span v-else>There were {{duration}} available for completion and submission of this
                    assessment.</span>
                </div>
            </div>
            <div v-else>
                <div class="text-danger">
                    Your assessment was due <strong>by {{actualend.format('HH:mm:ss on Do MMMM')}}</strong>.
                </div>
                <div class="alert alert-danger mt-2 mb-0">
                    <p>
                        You downloaded this assessment at {{downloadedAt.format('HH:mm:ss on Do MMMM')}}.
                        <span v-if="downloadedAt.isAfter(recommendedDownloadDeadline)">There was insufficient time
                        before the deadline to enable the maximum permitted time for this assessment.</span>
                        <span v-else>There were {{duration}} available for completion and submission of this
                        assessment.</span>
                    </p>
                    <p class="mb-0">
                        <strong>
                            Click "Submit Assessment" <em>now</em> to see if you still have
                            time to submit your assessment, and for guidance on what to do if
                            your deadline has passed.
                        </strong>
                    </p>
                </div>
            </div>
        </b-card-text>

        <div v-if="entry.aepAssessment" class="alert alert-info mt-2 mb-0">
            This assessment is taking place on the
            <a class="alert-link" href="https://altexams.warwick.ac.uk/assessments">Alternative Exams Portal (AEP)</a>.
        </div>
        <span v-else :id="entry.assignmentId + '-download'">
            <b-button
                    :disabled="start.isAfter(now) || attemptAction"
                    v-if="!entry.downloaded"
                    @click="doAction"
                    variant="success">
                Download Assessment
            </b-button>
            <b-button
                    :disabled="attemptAction"
                    v-else-if="!entry.submitted"
                    @click="doAction"
                    variant="success">
                Submit Assessment
            </b-button>
        </span>
        <b-tooltip
                v-if="start.isAfter(now)"
                :target="entry.assignmentId + '-download'"
                triggers="hover"
                placement="right">
            This assessment is not yet available for download
        </b-tooltip>
        <b-tooltip
                v-if="start.isAfter(now)"
                :target="entry.assignmentId + '-download'"
                triggers="hover"
                placement="right">
            This assessment is not yet available for download
        </b-tooltip>

        <template v-slot:footer>
            <b-col sm="12" md="6" class="pl-0 pr-sm-0">
                <start-to-end-time :start="start" :end="end"/>
            </b-col>
            <b-col sm="12" md="6" class="border-md-left pr-0 pl-0 pl-md-3" v-if="entry.duration">
                <font-awesome-icon :icon="['far', 'clock']"/>
                {{duration}}
            </b-col>
        </template>

    </entry-wrapper>

</template>

<script>

    import humanizeDuration from "humanize-duration";

    import MyWbsService from "@/services/mywbs.service";
    import EntryWrapper from "@/components/feed/fragments/EntryWrapper";
    import StartToEndTime from "@/components/feed/fragments/StartToEndTime";

    export default {
        name: "RollingDeadline",
        components: {EntryWrapper, StartToEndTime},
        props: {
            entry: {
                type: Object,
                required: true
            },
            now: {
                type: Object,
                required: true
            },
            startCountdownCutoffDuration: {
                type: Array,
                required: true
            }
        },
        data: function() {
            return {
                attemptAction: false,
                start: this.entry.start,
                end: this.entry.end
            }
        },
        computed: {
            downloadSoon: function() {
                return (
                    !this.entry.downloaded
                    && this.start.isBefore(this.now.clone().add(...this.startCountdownCutoffDuration))
                    && this.now.isBefore(this.start)
                );
            },
            downloadNow: function() {
                return (
                    !this.entry.downloaded
                    && this.start.isBefore(this.now)
                    && this.now.isBefore(this.recommendedDownloadDeadline)
                )
            },
            downloadLate: function() {
                return (
                    !this.entry.downloaded
                    && this.start.isBefore(this.now)
                    && this.now.isBefore(this.end)
                )
            },
            deadlinePassed: function() {
                return this.actualend.isBefore(this.now)
            },
            downloadedAt: function() {
                return this.$moment(this.entry.downloaded)
            },
            duration: function() {
                return this.entry.duration ? humanizeDuration(this.entry.duration * 1000 * 60) : 'unknown';
            },
            timeFromStart: function() {
                const diff = this.start.diff(this.now);
                return diff >= 60000
                    ? humanizeDuration(diff, { units: ['h', 'm'], round: true})
                    : "less than a minute";
            },
            actualend: function () {
                return this.$moment(this.entry.deadline);
            },
            recommendedDownloadDeadline: function () {
                return this.end.clone().subtract(this.entry.duration, 'minutes');
            }
        },
        methods: {
            doAction() {
                this.attemptAction = true;
                MyWbsService.authenticateForAssignmentSubmission(this.entry)
                    .then(url => {
                        window.location.replace(`${url}`);
                    })
                    .catch(() => {
                        this.$bvToast.toast("There was an error redirecting to my.wbs", {
                            title: "Failed to go to my.wbs"
                        });
                    })
                    .finally(() => {
                        this.attemptAction = false;
                    });
            }
        }
    }
</script>
