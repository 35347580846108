export function buildUserFromClaims(claims) {
    return {
        id: claims?.user?.id,
        name: claims?.user?.name,
        status: claims?.user?.status,
        canAccessMyWbs: claims?.canAccessMyWbs,
        programmeEmail: claims?.programmeEmail,
        programme: claims?.user.programme,
        canImpersonate: claims?.canImpersonate,
        isImpersonating: !!claims?.user?.impersonator,
        isStaff: claims?.user?.status === "Staff",
        workshopTitle: claims?.workshopTitle,
        isTwoFactorEnabled: claims?.user?.twoFactorEnabled, // is 2FA enabled for the user account
        isTwoFactorAuthRequired: claims?.isTwoFactorAuthRequired, // does the user currently need to perform 2FA auth
        isAllowedToTimeShift: claims?.user?.allowedToTimeShift,
        ssoId: claims?.ssoId,
        ssoLoginHint: claims?.ssoLoginHint
    };
}