export default class User {
  constructor(username, email, password, canImpersonate) {
    this.username = username;
    this.email = email;
    this.password = password;
    this.passwordMinLength=4;
    this.usernameMinLength=4;
    this.passwordInvalidMessage = `Enter at least ${this.passwordMinLength} characters`;
	this.usernameInvalidMessage = `Enter at least ${this.usernameMinLength} characters`;
	this.canImpersonate = canImpersonate;
  }
}
