import moment from "moment-timezone"

export default class FeedEntry {

    constructor(jsonEntry) {

        // copies across all self-defined properties from the jsonEntry
        Object.assign(this, jsonEntry);

        this.start = moment(jsonEntry.start);
        this.end = moment(jsonEntry.end);

        this.downloadableFiles = (this.fileAttachments || []).filter(f => !f.inlineImage)

    }

    async hash() {

        async function sha1HexShort( str ) {

            const buffer = new TextEncoder( 'utf-8' ).encode( str );
            const digest = await crypto.subtle.digest('SHA-1', buffer);

            // Convert digest to hex string
            const result = Array.from(new Uint8Array(digest)).map( x => x.toString(16).padStart(2,'0') ).join('');

            return result.substr(0,8);

        }

        if (!this.storedHash) {

            this.storedHash = await sha1HexShort(this.id);

        }

        return this.storedHash;

    }

}
