import Vue from 'vue';
import App from './App.vue';

import './assets/custom.scss';

import router from './router';
import store from './store';
import VueAnalytics from 'vue-analytics'

import Vuex from 'vuex';
import VueCookies from 'vue-cookies';
import VeeValidate from 'vee-validate';
import BootstrapVue from 'bootstrap-vue'

import VueCompositionAPI from '@vue/composition-api';
Vue.use(VueCompositionAPI);

//Set up MSAL
import { msalPlugin } from "@/composition-api/useMsal";
import { msalInstance } from "./store/auth.config";
Vue.use(msalPlugin, msalInstance);

// This imports all the layout components such as <b-container>, <b-row>, <b-col>:
import { LayoutPlugin } from 'bootstrap-vue'
Vue.use(LayoutPlugin);

// This imports <b-modal> as well as the v-b-modal directive as a plugin:
import { ModalPlugin } from 'bootstrap-vue'
Vue.use(ModalPlugin);

// This imports <b-card> along with all the <b-card-*> sub-components as a plugin:
import { CardPlugin } from 'bootstrap-vue'
Vue.use(CardPlugin);

Vue.use(Vuex);
Vue.use(VueCookies);
Vue.use(VeeValidate);
Vue.use(BootstrapVue, {
    "BToast": {
        "toaster" : 'b-toaster-bottom-right',
        "variant" : 'danger'
    }
});

const id = process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID;
Vue.use(VueAnalytics, {
	id,
	router
});

// make moment available to vue expressions (eg, {{ moment(date).format() }} )
import moment from 'moment-timezone';
Vue.prototype.$moment = moment;
moment.locale("en-GB");
moment.tz.setDefault("Europe/London");

// add method for enabling mathJax when required
Vue.prototype.$mathJax = async function(element) {

    if (!window.MathJax) {

        console.log('enabling mathjax');

        window.MathJax = {
            tex: { inlineMath: [['$', '$']] },
            typeset: null
        }

        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js';
        document.head.appendChild(script);

        // await it being loaded and ready
        while (!window.MathJax.typeset) {
            await new Promise(resolve => setTimeout(resolve, 10));
        }

    }

    window.MathJax.typeset([element]);

}


// import FontAwesome basics
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

// import FontAwesome icons
import {
	faSignOutAlt,
	faUser,
	faClock as fasClock,
	faCalendarDay,
	faPencilAlt,
	faUserSecret,
	faCaretRight,
	faChalkboardTeacher,
	faSpinner,
	faLink,
	faStar,
	faPaperclip,
	faCloudDownloadAlt,
	faFileAlt,
	faDesktop,
	faUsers,
    faUserFriends,
	faMapMarkerAlt,
	faComments,
    faHome,
	faMedkit,
	faQuestion,
	faHeart,
	faAmbulance,
	faBullhorn,
	faComputer,
	faChevronRight,
	faChevronDown,
	faQuestionCircle,
	faInfoCircle
} from '@fortawesome/free-solid-svg-icons'

import {
	faCalendarAlt,
    faCalendarCheck,
	faClock as farClock,
	faEnvelope
} from '@fortawesome/free-regular-svg-icons'

// register FontAwesome icons
library.add(
	faSignOutAlt,
	faUser,
	fasClock,
	faCalendarDay,
	faPencilAlt,
	faUserSecret,
	faCaretRight,
	faChalkboardTeacher,
	faSpinner,
	faCalendarAlt,
    faCalendarCheck,
	farClock,
	faQuestion,
	faEnvelope,
	faLink,
	faStar,
	faPaperclip,
	faCloudDownloadAlt,
	faFileAlt,
	faUsers,
    faUserFriends,
	faDesktop,
	faMapMarkerAlt,
	faComments,
    faHome,
	faMedkit,
	faHeart,
	faAmbulance,
	faBullhorn,
	faComputer,
	faChevronRight,
	faChevronDown,
	faQuestionCircle,
	faInfoCircle,
);

// register FontAwesome components
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);

import ProgressSpinner from "./components/util/ProgressSpinner";
Vue.component('progressSpinner', ProgressSpinner);

Vue.config.productionTip = false;

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
});

