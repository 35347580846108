import Vue from 'vue';
import Router from 'vue-router';
import moment from 'moment-timezone';

import HomePage from './views/HomePage.vue';
import PageNotFound from './views/PageNotFound.vue';
import LoginForm from './views/LoginForm.vue';
import TwoFactorForm from './views/TwoFactorForm.vue';
import MyWBS from './views/goToMyWbs.vue';
import OIDCLogoutPage from './views/OIDCLogoutPage.vue';
import store from "@/store";

Vue.use(Router);

export function extractTimeShiftProp(route, hasPermission){
    const timeShiftParam = route.query.timeShift;
    const timeShift = timeShiftParam && hasPermission
        ? moment(timeShiftParam)
        : null
    return { timeShiftFrom: timeShift };
}

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomePage,
            props: route => extractTimeShiftProp(route, store.getters['auth/canTimeShift'])
        },
        {
            path: '/:date(\\d{4}-[01]\\d-[0-3]\\d)/:time([0-2]\\d:[0-5]\\d:[0-5]\\d)/:id([0-9a-f]{8})',
            name: 'entry',
            component: HomePage
        },
        {
            path: '/login',
            name: 'login',
            component: LoginForm
        },
        {
            path: '/twofactor',
            name: 'twoFactor',
            component: TwoFactorForm
        },
        {
            path: '/goToMyWbs',
            name: 'goToMyWbs',
            component: MyWBS
        },
        {
            path: '/oidc-logout',
            name: 'OIDCLogoutPage',
            component: OIDCLogoutPage
        },
        {
            path: "*",
            name: '404',
            component: PageNotFound
        }
    ]
});

export function authRedirect(to, query, isFullyAuthenticated, isLoggedIn){

    if (isFullyAuthenticated && (to.name === 'login' || to.name === 'twoFactor')) {

        return {name: 'home', query, replace: true};

    } else if (!isFullyAuthenticated && isLoggedIn && to.name !== 'twoFactor') {

        return {name: 'twoFactor', query, replace: true};

    } else if (!isLoggedIn && (to.name !== 'login' && to.name !== 'OIDCLogoutPage')) {

        return {name: 'login', query, replace: true};

    } else {

        return undefined;

    }

}

let refreshed = false;

router.beforeEach(async (to, from, next) => {

    async function loggedIn() {
        if (!(store.getters['auth/isLoggedIn'] || refreshed)) {
            await store.dispatch('auth/refreshToken').catch(() => {});
            refreshed = true;
        } else {
            refreshed = false;
        }
        return store.getters['auth/isLoggedIn'];
    }

    const query = to.name !== 'home' ? { to : to.path } : "";

    const isLoggedIn = await loggedIn();

    next(authRedirect(to, query, store.getters['auth/isFullyAuthenticated'], isLoggedIn));

});

export default router;