<template>

    <b-card no-body class="h-100 shadow-sm">

        <b-card-header :class="type">
            <slot name="header">
                <font-awesome-icon class="float-right" v-if="!entry.addedToStudentLibrary" :icon="icon" size="lg"/>
                <font-awesome-layers v-else full-width class="float-right fa-lg">
                    <font-awesome-icon :icon="icon"/>
                    <font-awesome-icon :icon="['fas','star']" transform="shrink-6 up-7 right-11"/>
                </font-awesome-layers>
                <h2>
                    <small>
                        {{ typeName || displayedTypeName }}
                        <span v-if="entry.addedToStudentLibrary" class="font-weight-normal">
                         - in your personal calendar
                        </span>
                    </small>
                </h2>
                <h3>
                    <span v-if="entry.moduleCode">{{ entry.moduleCode }} -</span> {{ entry.moduleTitle }}
                    <small v-if="entry.moduleOccasion > 0">#{{ entry.moduleOccasion }}</small>
                </h3>
            </slot>
        </b-card-header>

        <b-card-body>

            <b-card-title>
                <span v-if="title">{{ title }}</span>
                <span v-else v-html="entry.title"></span>
            </b-card-title>

            <slot/>

        </b-card-body>

        <b-card-footer footer-bg-variant="white" footer-border-variant="white" class="pt-0">
            <b-container>
                <b-row>
                    <slot name="footer">
                        <b-col sm="12" md="6" class="pl-0 pr-sm-0">
                            <font-awesome-icon :icon="['far', 'calendar-alt']"/>
                            {{ start.format('HH:mm on Do MMMM') }}
                        </b-col>
                        <b-col v-if="hasDuration" sm="12" md="6" class="border-md-left pr-0 pl-0 pl-md-3">
                            <font-awesome-icon :icon="['far', 'clock']"/>
                            {{duration}}
                        </b-col>
                    </slot>
                </b-row>
            </b-container>
        </b-card-footer>

    </b-card>

</template>

<script>

    import humanizeDuration from "humanize-duration";

    export default {
        name: "EntryWrapper",
        props: {
            entry: {
                type: Object,
                required: true
            },
            icon: {
                type: Array,
                required: true
            },
            type: {
                type: String,
                required: true
            },
            typeName: {
                type: String,
                required: false
            },
            title: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                start: this.entry.start,
                end: this.entry.end,
                entryTypeName: this.entry.typeName,
            }
        },
        computed: {
            duration() {
                return humanizeDuration(this.start.diff(this.end))
            },
            hasDuration() {
                return !this.start.isSame(this.end)
            },
          displayedTypeName() {
              return this.type === "workshop" ? this.$store.getters['auth/user']['workshopTitle'] : this.entryTypeName;
          }
        }
    }
</script>
