<template>
    <b-col xs="12" sm="12" md="6" :class="`mb-4 ${classes}`" :id="hash">
        <RollingDeadline
            v-if="isRollingDeadline"
            :entry="entry"
            :now="now"
            :start-countdown-cutoff-duration="startCountdownCutoffDuration"/>
        <AssignmentEntry
            v-else-if="isAssignment"
            :entry="entry"
            :now="now"/>
        <ScheduledEntry
            v-else-if="isScheduledEntry"
            :entry="entry"
            :now="now"/>
        <ProgressMeeting
            v-else-if="isProgressMeeting"
            :entry="entry"
            :now="now"
            :start-countdown-cutoff-duration="startCountdownCutoffDuration"/>
        <DurationEntry
            v-else
            :entry="entry"
            :now="now"
            :start-countdown-cutoff-duration="startCountdownCutoffDuration"
            :join-cutoff-duration="joinCutoffDuration"/>
    </b-col>
</template>

<script>

    import AssignmentEntry from "./entries/AssignmentEntry";
    import DurationEntry from "./entries/DurationEntry";
    import RollingDeadline from "./entries/RollingDeadline";
    import ScheduledEntry from "./entries/ScheduledEntry";
    import ProgressMeeting from "./entries/ProgressMeeting";

    export default {
        name: "FeedEntry",
        components: { DurationEntry, AssignmentEntry, RollingDeadline, ScheduledEntry, ProgressMeeting },
        props: {
            entry: {
                type: Object,
                require: true
            },
            now: {
                type: Object,
                require: true
            },
            startCountdownCutoffDuration: {
                type: Array,
                required: true
            },
            joinCutoffDuration: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                hash : "",
                classes : ""
            }
        },
        async mounted() {
            this.hash = await this.entry.hash();
            this.classes = this.hash === this.$route.params.id ? 'focus' : '';
        },
        computed: {
            isRollingDeadline() {
                return this.entry.type === 'ROLLING_DEADLINE'
            },
            isAssignment() {
                return this.entry.type === 'ASSIGNMENT'
            },
            isResource() {
                return this.entry.type === 'SCHEDULED_RESOURCE'
            },
            isAnnouncement() {
                return this.entry.type === 'SCHEDULED_ANNOUNCEMENT'
            },
            isScheduledEntry() {
                return this.isResource || this.isAnnouncement
            },
            isProgressMeeting() {
                return this.entry.type === 'PROGRESS_MEETING'
            }
        }
    }

</script>
