<template>
    <div class="container">
        <div v-if="timeShiftFrom" class="alert alert-warning">
            Fake time: {{now.format("DD/MM/YYYY HH:mm:ss")}}
        </div>
        <div v-if="!feedLoaded" class="loading-overlay">
            <progress-spinner size="2x"/>
        </div>
        <b-container v-else class="mt-4">
            <b-tabs pills>
                <b-tab @click="setChannel" title="all" :active="!channels.includes(channel)"/>
                <b-tab
                    @click="setChannel"
                    v-for="c in channels"
                    :title="c"
                    :key="c"
                    :active="c === channel"/>
            </b-tabs>
            <h1 class="my-4">Happening Now</h1>
            <b-row>
                <FeedEntry
                        v-for="entry in happeningNow"
                        :entry="entry"
                        :key="entry.id"
                        :now="now"
                        :start-countdown-cutoff-duration="startCountdownCutoffDuration"
                        :join-cutoff-duration="joinCutoffDuration"/>
                <b-col v-if="!happeningNow.length">
                    <p>There are no events happening in the next {{happeningNowDuration}}.</p>
                </b-col>
            </b-row>
            <h1 class="my-4" v-if="relevantNow.length">Relevant Now</h1>
            <b-row v-if="relevantNow.length">
                <FeedEntry
                        v-for="entry in relevantNow"
                        :entry="entry"
                        :key="entry.id"
                        :now="now"
                        :start-countdown-cutoff-duration="startCountdownCutoffDuration"
                        :join-cutoff-duration="joinCutoffDuration"/>
            </b-row>
            <h1 class="my-4">Upcoming</h1>
            <b-row>
                <FeedEntry
                        v-for="entry in upcoming"
                        :entry="entry"
                        :key="entry.id"
                        :now="now"
                        :start-countdown-cutoff-duration="startCountdownCutoffDuration"
                        :join-cutoff-duration="joinCutoffDuration"/>
                <b-col v-if="!upcoming.length">
                    <p>There are no events in the next {{upcomingDuration}}.</p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    import FeedEntry from '../components/feed/FeedEntry';
    import humanizeDuration from "humanize-duration";

    import fetchFeed from "../controller/fetchFeed";
    import sendVideoSessionsToServer from "@/services/synchronize_video_analytics.service";

    export default {
        name: 'HomePage',
        components: { FeedEntry },
        props: {
          timeShiftFrom: {
            type: Object,
            required: false
          }
        },
        data() {

            // timeshift to use for debugging/dev purposes
            const now = this.timeShiftFrom ? this.timeShiftFrom : this.$moment();
            const timeShiftDiffInSeconds = this.timeShiftFrom
                ? Math.floor(this.$moment.duration(this.timeShiftFrom.diff(this.$moment())).asSeconds())
                : 0;

            // define various cutoff times for when entry states change in the UI
            const recentlyFinishedCutoffDuration = [10, 'minutes'];
            const happeningNowCutoffDuration = [30, 'minutes'];
            const upcomingCutoffDuration = [7, 'days'];
            const joinCutoffDuration = happeningNowCutoffDuration;
            const startCountdownCutoffDuration = [2, 'hours'];

            const scheduledEntryTypes = ["SCHEDULED_RESOURCE", "SCHEDULED_ANNOUNCEMENT"];

            return {
                content: '',
                happeningNowCutoffDuration,
                recentlyFinishedCutoffDuration,
                upcomingCutoffDuration,
                joinCutoffDuration,
                startCountdownCutoffDuration,
                from: now.clone().subtract(...recentlyFinishedCutoffDuration),
                to: now.clone().add(...upcomingCutoffDuration),
                feed: [],
                feedLoaded: false,
                now,
                timeShiftDiffInSeconds,
                interval: null,
                scrolled: false,
                channel: this.$cookies.get("mini-my-channel") || 'all',
                scheduledEntryTypes
            };

        },
        computed: {
            channelFeed() {
                return this.channel === 'all' || !this.channels.includes(this.channel)
                    ? this.feed
                    : this.feed.filter(e => e.channels.includes(this.channel))
            },
            happeningNowCutoff() {
                return this.now.clone().add(...this.happeningNowCutoffDuration)
            },
            recentlyFinishedCutoff() {
                return this.now.clone().subtract(...this.recentlyFinishedCutoffDuration)
            },
            upcomingCutoff() {
                return this.now.clone().add(...this.upcomingCutoffDuration)
            },
            happeningNowDuration() {
                return humanizeDuration(this.happeningNowCutoff.diff(this.now))
            },
            upcomingDuration() {
                return this.upcomingCutoffDuration.join(" ");
            },
            happeningNow() {
                return this.channelFeed
                    .filter(e => this.recentlyFinishedCutoff.isBefore(e.end))
                    .filter(e => this.happeningNowCutoff.isAfter(e.start))
                    .filter(e => !this.scheduledEntryTypes.includes(e.type));
            },
            relevantNow() {
                return this.channelFeed
                    .filter(e => this.now.isBefore(e.end))
                    .filter(e => this.now.isAfter(e.start))
                    .filter(e => this.scheduledEntryTypes.includes(e.type))
                    .sort((e1, e2) => e1.end.diff(e2.end));
            },
            upcoming() {
                return this.channelFeed
                    .filter(e => this.happeningNow.indexOf(e) < 0)
                    .filter(e => this.relevantNow.indexOf(e) < 0)
                    .filter(e => e.end.isAfter(this.now));
            },
            channels() {
                return [...new Set(this.feed.flatMap(e => e.channels))].sort();
            }
        },
        async created() {
            this.feed = await fetchFeed(this.timeShiftDiffInSeconds);
            this.feedLoaded = true;
            await sendVideoSessionsToServer();
        },
        mounted() {

            this.interval = setInterval(() => {
                this.now = this.$moment().add(this.timeShiftDiffInSeconds, 'seconds');
            }, 10000)

        },
        methods : {

            focus(focussed) {

                focussed.scrollIntoView({ block: "center" });

                const scrolledY = window.scrollY;
                const stickyHeight = this.$root.$el.querySelector(".sticky-top").clientHeight;
                const top = focussed.getBoundingClientRect().top;

                // extra scroll if hidden behind sticky elements at top
                if (scrolledY && top - 12 < stickyHeight) {
                    window.scroll(0, scrolledY - stickyHeight + top - 12);
                }

                const removeFocus = () => {
                    window.removeEventListener("scroll", removeFocus);
                    focussed.classList.remove("focus");
                }

                setTimeout(() => window.addEventListener("scroll", removeFocus), 1000)

            },

            entryNotFound() {

                const start = this.$moment(`${this.$route.params.date}T${this.$route.params.time}Z`)

                const reason = start.isValid() && (start.isBefore(this.from) || start.isAfter(this.to))
                    ? `Only items in the next ${this.upcomingDuration} are currently available on mini-my.`
                    : "You may have followed an invalid link, or the item might not be available to your user.";

                this.$bvToast.toast(reason, {
                    title : "Linked item not found",
                    variant : "warning"
                });

            },

            setChannel(e) {
                this.channel = e.target.innerText.toLowerCase();
                this.$cookies.set("mini-my-channel", this.channel)
            }

        },
        async updated() {

            // this is a necessary evil for the workings of the hidden css pseudo-elements
            // that force the nav pills to be the right width for when the text is bold

            [...this.$el.getElementsByClassName("nav-link")]
                .forEach(e => e.setAttribute("data-title", e.innerText));

            if (!this.scrolled && this.$route.params.id) {

                // need to ensure all feed have calculated their hashes
                await Promise.all(this.feed.map(e => e.hash()));

                this.scrolled = true;

                const focussed = this.$el.querySelector(".focus");

                if (focussed) {

                    this.focus(focussed)

                } else {

                    this.entryNotFound();

                }

            }

        }
    };
</script>

<style scoped>
    .loading-overlay {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100px;
        z-index: 10;
    }
</style>
