<template>
    <b-nav-item
        class="pr-1 pr-md-0"
        v-b-modal="modal"
        :id="id">
        <b-btn tabindex="-1" :variant="variant" :aria-label="ariaLabel" pill>
            <font-awesome-icon :icon="icon"/>
        </b-btn>
        <b-tooltip :target="id" triggers="hover" placement="bottom">{{ toolTip }}</b-tooltip>
    </b-nav-item>
</template>

<script>
    export default {
        name: 'NavButton',
        props: {
            modal: {
                type: String,
                require: true
            },
            variant: {
                type: String,
                require: true
            },
            icon: {
                type: Array,
                require: true
            },
            toolTip: {
                type: String,
                require: true
            },
            ariaLabel: {
                type: String,
                default() { 
                    return this.toolTip 
                }
            }
        },
        data() {
            return {
                id: `${this.modal}-link`
            }
        }
    }
</script>
