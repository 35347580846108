<template>
    <div class="container wrapper">
        <div role="heading" aria-level="1" class="row align-items-center my-auto h-100">
            <b-card class="card card-container img-top border-0 p-0 mx-auto" no-body>
                <b-card-img fluid src="../assets/wbs_primelogo.svg"  alt="Warwick Business School Logo"/>

                <b-card-body class="px-0 overflow-auto">
                    <hr/>

                    <b-form @submit.prevent>
                        <div>
                            <b-form-group
                                    id="fieldset-1"
                                    :invalid-feedback="user.usernameInvalidMessage"
                                    :state="validateUsername"
                            >
                                <b-form-input
                                        class="text-center"
                                        placeholder="Username..."
                                        id="username"
                                        v-model="user.username"
                                        v-validate="'required'"
                                        :state="validateUsername"
                                        :disabled="authLoading"
                                        @input="checkUsername"
                                        v-on:keyup.stop.enter="handleUsername"
                                        type="text"
                                        name="username"
                                        trim
                                />
                            </b-form-group>
                        </div>

                        <div v-if="azureFeatureEnabled && !showPassword">
                          <b-form-group>
                            <b-button
                                @click="handleNext"
                                block
                                variant="primary"
                                :disabled="loading || authLoading"
                                class="login"
                            >
                              <progress-spinner v-show="loading || authLoading" class="mr-2" size="sm"/>
                              <font-awesome-icon :icon="['fas', 'caret-right']" class="mt-1"
                                                 style="float:right;"/>
                              <span>Next</span>
                            </b-button>
                          </b-form-group>
                        </div>

                        <div v-if="shouldShowPassword">
                          <div>
                              <b-form-group
                                      id="fieldset-2"
                                      :invalid-feedback="user.passwordInvalidMessage"
                                      :state="validatePassword"
                              >
                                  <b-form-input
                                          ref="password"
                                          class="text-center"
                                          placeholder="Password..."
                                          id="password"
                                          v-model="user.password"
                                          v-validate="'required'"
                                          :state="validatePassword"
                                          v-on:keyup.stop.enter="handleLogin"
                                          type="password"
                                          name="password"
                                          trim
                                  />
                              </b-form-group>
                          </div>

                          <div>
                              <b-form-group>
                                  <b-button
                                          @click="handleLogin"
                                          block
                                          variant="primary"
                                          :disabled="loading"
                                          class="login"
                                  >
                                      <progress-spinner v-show="loading" class="mr-2" size="sm"/>
                                      <font-awesome-icon :icon="['fas', 'caret-right']" class="mt-1"
                                                         style="float:right;"/>
                                      <span>Sign In</span>
                                  </b-button>
                              </b-form-group>
                          </div>

                          <div v-if="azureFeatureEnabled">
                            <b-form-group>
                              <b-button
                                  @click="handleCancel"
                                  block
                                  variant="light"
                                  class="cancel"
                              >
                                <span>Cancel</span>
                              </b-button>
                            </b-form-group>
                          </div>
                        </div>
                        <div v-if="showHint">
                          <b-form-group>
                            <div class="alert alert-info mb-0" role="alert">
                              <strong>
                                <font-awesome-icon
                                  :icon="['fas', 'info-circle']"
                                  class="mr-1"
                                />
                                Useful Information
                              </strong>
                              <div v-html="hint"></div>
                            </div>
                          </b-form-group>
                        </div>
                        <div v-if="message">
                            <b-form-group>
                                <div class="alert alert-danger mb-0" role="alert">{{message}}</div>
                            </b-form-group>
                        </div>
                    </b-form>
                    <div class="mt-4" v-if="shouldShowPassword">
                         <b-link target="_blank" :href="passwordResetUrl">Forgotten your password?</b-link>
                    </div>
                    <div class="mt-2">
                        <b-link target="_blank" :href="termsOfUseUrl">Terms of use</b-link>
                    </div>
                    <div class="mt-2">
                      <b-link v-b-modal.accessibilitystatement tool-tip="Accessibility Statement">Accessibility Statement</b-link>
                    </div>

                  <div class="content-divider"></div>
                  <div class="appstatusindicator">
                    <b-link target="_blank" href="https://status.wbs.ac.uk" rel="Warwick Business School service status page">
                      <div class="status-container">
                        <div class="status-indicator" :style="indicatorBgColor"></div>
                        <div class="status-text">{{ serviceStatus }}</div>
                      </div>
                    </b-link>
                  </div>
                </b-card-body>
            </b-card>
        </div>
      <!-- Accessibility statement modal -->
      <AccessibilityStatement/>
    </div>
</template>

<script>
import http from "../services/http.service";
import User from "../models/user";
import AccessibilityStatement from "../components/AccessibilityStatement";
import {useMsal} from "@/composition-api/useMsal";
import {loginRequest} from "@/store/auth.config";

export default {
      name: "LoginForm",
      components: {AccessibilityStatement},
      data() {
        return {
          user: new User("", ""),
          loading: false,
          showPassword: false,
          authLoading: false,
          termsOfUseUrl: `http://www2.warwick.ac.uk/services/its/about/policies/`,
          passwordResetUrl: "https://my.wbs.ac.uk/?event=passwordreset",
          message: "",
          indicatorBgColor: "background-color:#A0A0A0",
          serviceStatus: "Status...",
          showHint: false,
          hint: '',
        };
      },
      created() {
        this.user.username = this.getUrlUsername();
      },
      async beforeMount() {
        await this.handleOIDCRedirect();
      },
      mounted() {
        this.getAppStatus();
      },
      computed: {
        validatePassword() {
          if (!this.user.password) return null;
          return this.user.password.length >= this.user.passwordMinLength;
        },
        validateUsername() {
          if (!this.user.username) return null;
          return this.user.username.length >= this.user.usernameMinLength;
        },
        azureFeatureEnabled() {
          return process.env.VUE_APP_AZURE_ACTIVEDIRECTORY_FEATURE_ENABLED === 'true';
        },
        shouldShowPassword() {
          return !this.azureFeatureEnabled || this.showPassword;
        }
      },
      methods: {
        onSubmit(ev) {
          ev.preventDefault();
        },
        handleMsalLogin(loginHint, authContext) {
          const { instance } = useMsal();
          loginRequest.loginHint = loginHint;

          if (authContext) {
            loginRequest.extraQueryParameters = {
              claims: JSON.stringify({
                "id_token": {
                  "acrs": {
                    "essential": true,
                    "value": authContext
                  }
                }
              })
            };
          }

          const currentAccounts = instance.getAllAccounts();
          const accountToUse = currentAccounts.find(account => account.username.toLowerCase() === loginHint.toLowerCase());

          if (accountToUse) {
            return instance.ssoSilent(loginRequest)
                .then(response => {
                  this.handleOIDCLogin(
                      (response.account.username || "").split("@")[0],
                      response.idToken
                  );
                })
                .catch(error => {
                  console.debug("Silent SSO failed, redirecting to login page", error);
                  instance.loginRedirect(loginRequest);
                });
          } else {
            instance.loginRedirect(loginRequest);
          }
        },
        handleLogin() {
          this.loading = true;
          this.$validator.validateAll().then(isValid => {
            if (!isValid) {
              this.loading = false;
              return;
            }

            if (this.user.username && this.user.password) {
              this.$store.dispatch("auth/login", this.user).then(
                  (response) => {
                    // Keep in sync with server-side logic
                    if (process.env.VUE_APP_AZURE_ACTIVEDIRECTORY_FEATURE_ENABLED === 'true') {
                      if (response && response.loginHint) {
                        this.showPassword = false;
                        this.handleMsalLogin(response.loginHint, response?.authContext);
                        return;
                      }
                    }

                    this.$router.replace(this.$route.query.to || "/");
                  },
                  error => {
                    this.loading = false;
                    this.message =
                        error.response?.status === 401
                            ? "Incorrect username or password."
                            : "Error logging in."
                  }
              );
            }
          });
        },
        checkUsername() {
          if (!this.azureFeatureEnabled) return;

          const index = this.user.username.toLowerCase().indexOf('@live.warw');
          if (index > 0) {
            this.message = "";
            const trimmed = this.user.username.substring(0, index).toLowerCase();
            this.hint = `It looks like you're typing a live.warwick username. You only need the first part (<b>${trimmed}</b>) to log in here.`;
            this.showHint = true;
          } else {
            this.showHint = false;
          }
        },
        handleOIDCLogin(username, idToken) {
          this.authLoading = true;
          this.user.username = username;
          this.$store.dispatch("auth/oidcSignin", idToken).then(
              () => {
                this.$router.replace(this.$route.query.to || "/");
              },
              error => {
                this.message =
                    error.response?.status === 401
                        ? "Login attempt unsuccessful. Please try again."
                        : "Error occurred while authenticating, please try again.";
                this.authLoading = false;
                this.loading = false;
              }
          );
        },
        getUrlUsername() {
          return this.$route?.query?.username || '';
        },
        handleUsername() {
          if (this.azureFeatureEnabled && !this.showPassword) {
            this.handleNext();
          } else {
            this.handleLogin();
          }
        },
        handleCancel () {
          if(this.azureFeatureEnabled) {
            this.showPassword = false;
            this.message = "";
            this.$store.dispatch("auth/resetLoginHint");
          }
        },
        handleNext() {
          if (!this.azureFeatureEnabled) {
            return; // This should never happen due to our template conditions, but just in case
          }

          this.loading = true;
          if (this.validateUsername) {
              let submittedUsername = this.user.username;
              const suffix = '@' + process.env.VUE_APP_AZURE_ACTIVEDIRECTORY_USER_IDENTITY;
              if (submittedUsername.toLowerCase().endsWith(suffix)) {
                submittedUsername = submittedUsername.slice(0, -suffix.length).toLowerCase();
              }
              this.$store.dispatch("auth/validateUsername", { username: submittedUsername }).then(
                  (response) => {
                    const loginHint = this.$store.getters["auth/loginHint"];
                    if (loginHint) {
                      this.handleMsalLogin(loginHint, response?.authContext);
                    } else {
                      this.loading = false;
                      this.message = "";
                      this.showPassword = true;
                      this.$nextTick(() => {
                        this.$refs.password.focus();
                      });
                    }
                  },
                  error => {
                    const errorStatus = error?.response?.data?.status || error?.data?.status || error?.status;
                    switch (errorStatus) {
                      case 404:
                      case 'username_invalid':
                        this.message = 'The username you entered is not valid.';
                        break;
                      case 429:
                      case 'rate_limit_exceeded':
                        this.message = 'You have exceeded the maximum number of login attempts. Please try again later.';
                        break;
                      default:
                        this.message = 'An error occurred while validating the username.';
                        break;
                    }
                    this.loading = false;
                  }
              );
          } else {
            this.loading = false;
            this.message = "Username is required.";
          }
        },
        async handleOIDCRedirect() {
          if (this?.$route?.query?.freshLogin === 'true') {
            const query = { ...this.$route.query };
            delete query['freshLogin'];
            await this.$router.replace({query});
            return;
          }

          this.authLoading = true;
          const { instance } = useMsal();
          try {
            const response = await instance.handleRedirectPromise();
            if (response) {
              this.handleOIDCLogin(
                  (response.account.username || "").split("@")[0],
                  response.idToken
              );
            } else {
              this.authLoading = false;
            }
          } catch (error) {
            this.authLoading = false;
            console.error(error);
          }
        },
        getAppStatus() {
          http.get("https://status.wbs.ac.uk/api/v2/status.json", {})
            .then((res) => {
               this.serviceStatus = res.data.status.description;
                switch (res.data.status.indicator) {
                  case "none" :
                    this.indicatorBgColor = "background-color:#2ecc71";
                    break;
                  case "critical" :
                    this.indicatorBgColor = "background-color:#fe2712";
                    break;
                  case "major" :
                    this.indicatorBgColor = "background-color:#fe5a1d";
                    break;
                  case "minor" :
                    this.indicatorBgColor = "background-color:#ffba00";
                    break;
                  default:
                    this.indicatorBgColor = "background-color:#FFFFFF";
                    break;
                }
            }).catch(error => {
              this.serviceStatus = `${error.message}, try again later`;
              this.indicatorBgColor = "background-color:#fe2712";
            });
        }
      }
    }
</script>

<style scoped>
    @media screen and (min-height: 600px) {
        .container.wrapper {
            height: 100vh;
        }
    }

    hr {
      margin-bottom: 1.6rem;
      border-top: 1px solid #f5f5f5;
    }

    .card-container.card {
        width: 275px !important;
    }

    .login {
        background-color: #0655a3;
        border-color: rgba(0, 0, 0, 0.1);
    }
    /* status indicator */
    .content-divider {
      margin: 1.4rem 0 0.8rem 0;
      width: 275px;
      border-top: 1px solid #f5f5f5;
    }
    .appstatusindicator {
      margin: 0 auto;
      width: 275px;
      line-height: 2.6rem;
      text-align: center;
      align-items: center;
    }
    .appstatusindicator .status-container {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.9rem;
    }
    .appstatusindicator div.status-indicator {
      width: 0.9rem;
      height: 0.9rem;
      border-radius: 50%;
      line-height: 1rem;
      margin-right: 1rem;
      background-color: #A0A0A0;
    }
</style>
