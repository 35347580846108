<template>
    <b-modal id="emergencies" size="lg" ok-only title="Emergencies & Wellbeing" title-tag="h1">
        <section aria-label="Emergencies & Wellbeing">
            <p>If you feel at immediate risk of harm or require emotional, psychological or mental health support, please see the services listed below:</p>
            <ul class="list-unstyled pl-4">
                <li class="pb-2">
                    <font-awesome-icon :icon="['fas', 'ambulance']" fixed-width class="mr-2"/>
                    <a href="https://warwick.ac.uk/services/wss/students/emergency_contacts/">Emergency Services</a>
                </li>
                <li>
                    <font-awesome-icon :icon="['fas', 'heart']" fixed-width class="mr-2"/>
                    <a href="https://warwick.ac.uk/services/wss">Wellbeing Support Services</a>
                </li>
              <li>
                <font-awesome-icon :icon="['fas', 'bullhorn']" fixed-width class="mr-2"/>
                <a href="https://reportandsupport.warwick.ac.uk/">Report + Support</a>
              </li>
            </ul>
        </section>
    </b-modal>
</template>

<script>
    export default {
        name: "EmergencyContacts"
    }
</script>

<style scoped>

</style>