<template>
    <b-row>
        <b-col sm=12 class="flexed">
            <h1>404 / Page Not Found</h1>
            <div class="mt-3">
                <a href="/"><font-awesome-icon :icon="['fas', 'home']"/> Home</a>
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "PageNotFound"
}
</script>

<style scoped>
    .flexed {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        margin-top: -74px;
    }
</style>