<template>
  <b-modal
      id="signOutOptions"
      title="Sign Out Options"
      size="lg"
      ok-only
      @ok="handleClose"
  >
    <template #modal-title>
      <h4>
        Sign Out Options
        <span v-b-tooltip.hover.bottom.html="tooltipHtml">
          <font-awesome-icon
              :icon="['fas', 'question-circle']"
              class="ml-1"
          />
        </span>
      </h4>
    </template>

    <p>Choose how you would like to sign out:</p>
    <div>
      <b>Sign out of a specific WBS service only</b>
      <ul>
        <li>This will sign you out of mini-my only.</li>
        <li>The next time you log in, you will not normally be required to provide your password or multi-factor authentication.</li>
      </ul>
    </div>
    <div>
      <b>Sign out of Single Sign-On</b>
      <ul>
        <li>Recommended on shared devices.</li>
        <li>This will sign you out of connected University of Warwick services where possible.</li>
        <li>You will need to provide your password and multi-factor authentication the next time you sign in.</li>
      </ul>
    </div>

    <template #modal-footer>
      <b-button variant="secondary" @click="signOut">
        Sign out
      </b-button>
      <b-button variant="primary" @click="signOutAll">
        Sign out of Single Sign-On
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {useMsal} from "@/composition-api/useMsal";

export default {
  name: 'SignOutModal',
  props: {
    logOut: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      tooltipHtml: `<p><strong>Why am I seeing this?</strong><br> You're signed in using Single Sign-On (SSO), which allows you to access multiple University of Warwick services with one login.</p>`
    }
  },
  methods: {
    handleClose() {
      this.$bvModal.hide('signOutOptions');
    },
    signOut() {
      this.$bvModal.hide('signOutOptions');
      this.logOut();
    },
    signOutAll() {
      const { instance } = useMsal();
      let logoutRequest = {};

      if(this.$store.getters["auth/ssoLoginHint"]) {
        logoutRequest.logoutHint = this.$store.getters["auth/ssoLoginHint"];
      }

      this.$store.dispatch("auth/logout")
          .then(() => {
            instance.logoutRedirect(logoutRequest);
          });
    }
  }
};
</script>
<style scoped>
  .fa-question-circle {
    cursor: pointer;
  }
</style>