import AuthService from '../services/auth.service';
import {buildUserFromClaims} from './auth.util';
import moment from 'moment-timezone';
import jwt_decode from 'jwt-decode';
import httpService from "../services/http.service";

const initialState = {
  jwt_token: null,
  refresh_interval_id: null,
  loginHint: null
};

export const auth = {
  namespaced: true,
  state: initialState,
  getters: {
    decodedToken: state => {
      return (state.jwt_token) ? jwt_decode(state.jwt_token) : null;
    },
    claims: state => {
      return state.jwt_token != null ? jwt_decode(state.jwt_token) : null;
    },
    token: state =>{
      return (state.jwt_token) ? state.jwt_token : null;
    },
    user: (state, getters) => {
      return buildUserFromClaims(getters.claims);
    },
    token_expiry: (state, getters) => {
      return getters.claims != null ? moment(getters.claims.exp, 'X') : null;
    },
    isLoggedIn: (state, getters) => {
      return getters.token_expiry != null && moment().isBefore(getters.token_expiry);
    },
    isFullyAuthenticated: (state, getters) => {
      return getters.isLoggedIn && !getters.claims.isTwoFactorAuthRequired;
    },
    isImpersonating: (state, getters) => {
      return getters.user.isImpersonating
    },
    canGoToMyWbs: (state, getters) => {
      return !!getters.user.canAccessMyWbs
    },
    canImpersonate: (state, getters) => {
      return !!getters.user.canImpersonate
    },
    canTimeShift: (state, getters) => {
      return !!getters.user.isAllowedToTimeShift;
    },
    loginHint: state => {
        return state.loginHint;
    },
    ssoId: (state, getters) => {
        return getters.claims?.ssoId
    },
    ssoLoginHint: (state, getters) => {
        return getters.claims?.ssoLoginHint
    }
  },
  actions: {
    login({ commit, dispatch }, user) {
      return AuthService.login(user).then(
        data => {
          // Keep in sync with server-side logic
          if (process.env.VUE_APP_AZURE_ACTIVEDIRECTORY_FEATURE_ENABLED === 'true') {
                if (data.status === 'success' && data.loginHint) {
                    commit('setLoginHint', data.loginHint);
                    return Promise.resolve(data);
                }
          }

          commit('loginSuccess', data);
          httpService.setAuthorizationHeader(data);
          return dispatch('startRefreshCountdown').then(() => Promise.resolve(data));
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      )
      .catch(e => Promise.reject(e));
    },
    twoFactor({ commit, dispatch, getters }, credentials) {
      return AuthService.twoFactor(getters.user?.id, credentials.code, credentials.durationDays).then(
          token => {
            commit('twoFactorSuccess', token);
            httpService.setAuthorizationHeader(token);
            return Promise.resolve(token);
          },
          error => {
            return Promise.reject(error);
          }
      )
      .then(() => dispatch('startRefreshCountdown'))
      .catch(e => Promise.reject(e));
    },
    validateUsername({ commit }, username) {
        return AuthService.validateUsername(username).then(
            response => {
                if (response.data && response.data.status === 'success') {
                    if (response.data.loginHint) {
                        commit('setLoginHint', response.data.loginHint);
                    }
                    return Promise.resolve(response.data);
                }
                else {
                    commit('resetLoginHint');
                    return Promise.reject(response);
                }
            },
            error => {
                commit('resetLoginHint');
                return Promise.reject(error);
            }
        ).catch(e => Promise.reject(e));
    },
    oidcSignin({ commit, dispatch }, idToken) {
        return AuthService.oidcSignin(idToken).then(
            token => {
                commit('twoFactorSuccess', token);
                httpService.setAuthorizationHeader(token);
                return Promise.resolve(token);
            },
            error => {
                commit('resetLoginHint');
                return Promise.reject(error);
            }
        )
        .then(() => dispatch('startRefreshCountdown'))
        .catch(e => Promise.reject(e));
    },
    logout({ commit }) {
      httpService.clearAuthorizationHeader();
      return AuthService.invalidateRefreshToken()
          .then(() => commit('logout'))
          .then(() => Promise.resolve());
    },
    refreshToken({ commit, dispatch, getters }) {
        return AuthService.refreshToken(getters.user?.id)
            .then(token => {commit('refreshedToken', token); httpService.setAuthorizationHeader(token);})
            .then(() => dispatch('startRefreshCountdown'))
            .then(() => Promise.resolve())
            .catch(() => {
              commit('refreshFailure');
              return Promise.reject("Failed to refresh authentication token");
            });
    },
    startRefreshCountdown( { commit, dispatch, getters } ) {
      const refresh = moment(getters.token_expiry, 'X').subtract(1, 'minutes');
      let interval = setTimeout(() => {
        dispatch('refreshToken').catch(console.log)
      }, refresh.diff(moment(), 'milliseconds'));
      commit('resetRefreshCounter', interval);
    },
    impersonate({ commit }, impersonationId) {
      return AuthService.impersonate(impersonationId).then(
          token => {
            commit('refreshedToken', token);
            return Promise.resolve();
          },
          error => {
            return Promise.reject(error);
          }
      );
    },
    resetImpersonation({ commit }) {
      return AuthService.resetImpersonation().then(
          token => {
            commit('refreshedToken', token);
            return Promise.resolve();
          },
          error => {
            return Promise.reject(error);
          }
      );
    },
    resetLoginHint({ commit }) {
        commit('resetLoginHint');
    }
  },
  mutations: {
    //AKA 'authenticated with token', if user is required to perform 2FA, jwt will only grant access to 2fa auth endpoint
    loginSuccess(state, token) {
      state.jwt_token = token;
    },
    loginFailure(state) {
      state.jwt_token = null;
    },
    twoFactorSuccess(state, token) {
      state.jwt_token = token;
    },
    refreshFailure(state) {
      state.jwt_token = null;
    },
    logout(state) {
      state.jwt_token = null;
      state.loginHint = null;
    },
    resetRefreshCounter(state, interval) {
      state.refresh_interval_id = interval;
    },
    refreshedToken(state, token) {
      state.jwt_token = token;
    },
    setLoginHint(state, loginHint) {
        state.loginHint = loginHint;
    },
    resetLoginHint(state) {
        state.loginHint = null;
    }
  }
};
